// Color system
$white: white;
$gray-100: hsl(0, 0, 95.7%);
$black: black;

$min-contrast-ratio: 3;

$primary: hsl(213.2, 50%, 29%);
$secondary: hsl(212.5, 95.8%, 53.1%);

$body-bg: $gray-100;


// Spacing
$spacer: 1rem;


// Components
$border-radius-lg: .75rem;

$box-shadow: 0 .25rem .4rem rgba($black, .25);


// Buttons + Forms
$input-btn-padding-x: $spacer;
$input-btn-border-width: 2px;


// Buttons
$btn-line-height: 1;


// Forms
$form-label-margin-bottom: .25rem;

$input-bg: hsl(0, 100%, 99%);


// Navbar
$navbar-padding-y: 0;
$navbar-padding-x: 1.5 * $spacer;

$navbar-brand-height: 24px;
$navbar-brand-padding-y: 9px;


// Spinners
$spinner-animation-speed: .9s;