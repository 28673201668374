.full-screen {
    position: absolute;
    top: var(--top-nav-height);
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: calc(100% - var(--top-nav-height));
}


$placeholder-shine-color: hsl(0, 0%, 86%) !default;

.loading-placeholder {
    animation: placeholder-shine 1s infinite;
    background-color: $placeholder-shine-color;
    background-image: linear-gradient(to right, transparent 33%, rgba(255, 255, 255, .5) 50%, transparent 66%);
    background-size: 300% 100%;
}


@keyframes placeholder-shine {
    0% {
        background-position: right;
    }
}