:root {
    --top-nav-height: 48px;
}


body {
    min-width: 360px;
    padding-bottom: $spacer;
}


.container-fluid {
    max-width: map-get($map: $container-max-widths, $key: "xl");
}


@include media-breakpoint-down(sm) {
    .no-margin-down-sm {
        padding-left: 0;
        padding-right: 0;

        .rounded-3 {
            border-radius: 0 !important;
        }
    }
}