.btn-outline-primary {
    background-color: $white;
    border-color: transparent;
    box-shadow: $box-shadow-sm;
}

.navbar-brand {
    margin-left: -.125em;
}

.on-hover-danger:hover {
    color: $danger;
}